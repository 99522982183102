// Sidebar menu light color scheme
.app-sidebar {
  &--light {
    .sidebar-navigation {

      .sidebar-menu-box {
        background: rgba($primary, .03);
      }

      .sidebar-header {
        color: rgba($black, .85);
      }

      ul {
        li {
          & > a {
            color: rgba($black, .8);

            .sidebar-icon {
              & > * {
                color: $primary;
                opacity: .45;
              }
            }

            .sidebar-icon-indicator {
              opacity: .4;
            }
          }

          ul {
            li {
              a {
                &::after {
                  background: rgba($black, .08);
                }

                &:hover,
                &.active {
                  &::after {
                    background: rgba($black, .12);
                  }
                }
              }
            }
          }
        }

        li > a:hover,
        li > a.active {
          color: $primary;
          background: rgba($primary, .05);

          .sidebar-icon-indicator {
            opacity: .7;
          }

          .sidebar-icon {
            & > * {
              opacity: 1;
              color: $primary;
            }
          }
        }
      }
    }
  }
}

.app-sidebar-collapsed {
  .app-sidebar--light:not(:hover) {
    .app-sidebar--content {
      .sidebar-navigation {
        .sidebar-header {
          &::after {
            background: rgba($black, .15);
          }
        }
      }
    }
  }
}
