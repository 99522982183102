
//  Core

// Ribbon mixin

@mixin ribbon($bgcolor1, $bgcolor2, $position: top-right) {
  > small {
    color: color-yiq($bgcolor1);
    background-color: $bgcolor1;
  }
}

// Base

.ribbon-angle {
  position: absolute;
  z-index: 8;
  width: 120px;
  height: 120px;
  overflow: hidden;

  & > small {
    position: absolute;
    display: block;
    width: 100%;
    padding: ($spacer / 4);
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $font-size-xs / 1.3;
  }

  &--top-right {
    top: 0;
    right: 0;

    & > small {
      transform: rotate(45deg);
      @include box-shadow(0 3px 6px -3px rgba($black, .5));
      top: 16px;
      left: 27px;
    }
  }

  &--top-left {
    top: 0;
    left: 0;

    & > small {
      transform: rotate(-45deg);
      @include box-shadow(0 3px 6px -3px rgba($black, .5));
      top: 16px;
      left: -27px;
    }
  }

  &--bottom-right {
    bottom: 0;
    right: 0;

    & > small {
      transform: rotate(-50deg);
      @include box-shadow(0 -3px 6px -3px rgba($black, .5));
      bottom: 21px;
      right: -31px;
    }
  }

  &--bottom-left {
    bottom: 0;
    left: 0;

    & > small {
      transform: rotate(50deg);
      @include box-shadow(0 -3px 6px -3px rgba($black, .5));
      bottom: 21px;
      left: -31px;
    }
  }
}

@each $color, $value in $theme-colors {
  .ribbon-#{$color} {
    @include ribbon($value, darken($value, 15%));
  }
}

.ribbon-horizontal {
  display: block;
  width: calc(100% + 20px);
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-left: -10px;
  margin-right: -10px;
  top: $spacer;
  position: absolute;
  z-index: 8;
  font-weight: bold;
  box-shadow: $box-shadow-layout-bottom;

  &::before, &::after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    bottom: -10px;
    border-top: 10px solid transparent;
  }

  &::before {
    left: 0;
    border-left: 10px solid transparent;
  }

  &::after {
    right: 0;
    border-right: 10px solid transparent;
  }

  &--bottom {
    top: auto;
    bottom: $spacer;
  }

  @each $color, $value in $theme-colors {
    &--#{$color} {
      background: $value;
      color: color-yiq($value);

      &::after,
      &::before {
        border-top-color: darken($value, 20%);
      }
    }
  }
}

.ribbon-vertical {
  position: absolute;
  width: 60px;
  padding: ($spacer / 1.5) 0 ($spacer / 2);
  top: -6px;
  left: ($spacer * 1.5);
  text-align: center;
  border-top-left-radius: 3px;
  z-index: 8;
  font-weight: bold;
  box-shadow: $box-shadow;

  &--right {
    left: auto;
    right: ($spacer * 1.5);
  }

  &::before {
    height: 0;
    width: 0;
    right: -5.5px;
    top: 0.1px;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  &::before, &::after {
    content: "";
    position: absolute;
  }

  &::after {
    height: 0;
    width: 0;
    bottom: -29.5px;
    left: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid transparent;
  }

  @each $color, $value in $theme-colors {
    &--#{$color} {
      background: $value;
      color: color-yiq($value);

      &::before {
        border-bottom-color: darken($value, 20%);
      }

      &::after {
        border-left-color: $value;
        border-right-color: $value;
      }
    }
  }
}

