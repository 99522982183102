//  Core

.wizard {
  .steps-indicator {
    padding: $card-spacer-y $card-spacer-x;
    border-bottom: $card-border-color solid 1px;
    background: $secondary;
    display: flex;
    list-style: none;
    margin: 0;

    li {
      display: block;
      width: auto;
      position: relative;
      padding: 0 ($spacer * 2) 0 0;

      a {
        justify-content: flex-start;
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        cursor: pointer;
        position: relative;

        .step-indicator {
          width: 38px;
          height: 38px;
          background: transparent;
          border: $gray-700 solid 2px;
          color: $gray-700;
          display: flex;
          align-items: center;
          font-weight: bold;
          justify-content: center;
          flex-shrink: 0;
          @include border-radius(100%);
          transition: $transition-base;
          position: relative;
        }

        .label {
          padding: 0 ($spacer / 1.5);
          color: $gray-700;
          transition: $transition-base;
          font-size: $font-size-base;
        }

        &:hover {
          .step-indicator {
            color: $gray-800;
            border-color: $gray-800;
          }

          .label {
            color: $gray-800;
          }
        }
      }

      .step-arrow {
        padding: 0;
        text-align: center;
        text-indent: initial;
        display: block;
        position: absolute;
        right: -19px;
        color: inherit;
        width: 16px;
        top: 50%;
        margin-top: -8px;
        height: 16px;
        line-height: 16px;
      }

      &.current {
        a {
          .step-indicator {
            background: $primary;
            border-color: $primary;
            color: $white;
          }

          .label {
            color: $primary;
          }
        }
      }

      &.done {
        a {
          .step-indicator {
            background: $success;
            color: $white;
            border-color: $success;
          }

          .label {
            color: $success;
          }
        }
      }

      &:last-child {
        .step-arrow {
          display: none;
        }
      }
    }
  }

  .wizard-steps {
    padding: 0;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    border-top: $card-border-color solid 1px;
  }

}

// Responsive

@include media-breakpoint-down(sm) {
  .wizard .steps ul {
    display: block;

    li {
      padding: 0 0 ($spacer * 2) 0;

      .step-arrow {
        transform: rotate(90deg);
        right: auto;
        left: 11px;
        bottom: ($spacer / 1.1);
        top: auto;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

// Style 1


.wizard-alternate {
  .steps-indicator {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: ($spacer / 2);

      a {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        font-size: $font-size-lg;
        color: $gray-600;
        cursor: pointer;
        padding: ($spacer) ($spacer * 3);
        @include border-radius($border-radius-sm);
        transition: $transition-base;

        .step-indicator {
          margin-bottom: ($spacer / 1.5);
          color: $blue;

          div {
            transition: $transition-base;
          }
        }
      }

      a:hover {
        .step-indicator {
          .bg-neutral-first {
            color: color-yiq($blue);
            background: $blue !important;
          }
        }
      }

      &.current a {
        background: $gray-100;
        color: $gray-800;
      }

      &.done a {
        background: $success-neutral;
        color: $gray-900;

        .step-indicator {
          .bg-neutral-first {
            color: color-yiq($success);
            background: $success !important;
          }
        }
      }
    }
  }
}

// Style 2


.wizard-alternate-2 {
  display: block;

  .steps-indicator {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: ($spacer / 2) ($spacer / 1.5) 0 0;
      flex-grow: 1;

      &:last-child {
        padding-right: 0;
      }

      a {
        display: flex;
        flex-direction: column-reverse;
        text-align: left;
        font-size: $font-size-base;
        color: $gray-900;
        background: transparentize($white, .5);
        cursor: pointer;
        padding: ($spacer * 1.5) ($spacer * 3);
        @include border-top-radius($border-radius);
        transition: $transition-base;

        .step-indicator {
          .stepper-wrapper {
            transition: $transition-base;
            background: $primary-neutral;
            color: color-yiq($primary-neutral);
          }
        }
      }

      &.current a,
      a:hover {
        background: $white;
        color: $primary;

        .step-indicator {
          .stepper-wrapper {
            color: color-yiq($primary);
            background: $primary;
          }
        }
      }

      &.done a {
        background: $white;
        color: $success;

        .step-indicator {
          .stepper-wrapper {
            color: color-yiq($success);
            background: $success !important;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      margin: (-$spacer / 2) (-$spacer / 2) $spacer;

      li {
        padding: 0;
        width: 50%;

        a {
          @include border-radius($border-radius);
          padding: $spacer;
          margin: ($spacer / 2);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;

      li {
        padding: 0;
        width: 100%;
      }
    }
  }

  .wizard-steps {
    margin: 0;
    background: $white;
    padding-top: 1.5rem;

    @include media-breakpoint-down(lg) {
      @include border-top-radius($border-radius);
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    @include border-bottom-radius($card-border-radius);
    background: $card-bg;
  }
}

// Vertical


.wizard.vertical {
  background: $secondary;
  @include border-bottom-radius($card-border-radius);

  .steps-indicator {
    width: 30%;
    float: left;
    border-bottom: 0;
    padding: 1.5rem;
    @include border-bottom-left-radius($card-border-radius);
    flex-direction: column;

    li {
      padding: 0 0 ($spacer * 2);

      &::after {
        display: none;
      }

      a {
        .step-indicator {
          &::after {
            content: '';
            background: $gray-400;
            width: 2px;
            @include border-radius($border-radius-lg);
            height: 16px;
            display: block;
            position: absolute;
            bottom: -$spacer;
            left: 50%;
            margin-left: -1px;
            top: 115%;
          }
        }

        .label {
          flex-grow: 1;
        }
      }

      &:last-child {
        a .step-indicator {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .wizard-steps {
    width: 70%;
    border-left: $card-border-color solid 1px;
    float: right;
    background: $white;
  }

  .actions {
    background: $white;
    border-left: 0;
    clear: both;
    @include border-bottom-radius($card-border-radius);
  }
}


// Responsive

@include media-breakpoint-down(sm) {
  .vertical {
    .actions,
    .wizard-steps {
      border-left: 0;
      float: none;
      width: 100%;
    }

    .wizard-steps {
      width: auto;
      background: $white;
      float: none;
      @include border-radius($border-radius);
      border: $card-border-color solid 1px;
      padding: $spacer;
      margin: $spacer;

      ul li a .step-indicator .step-arrow {
        height: 26px;
      }
    }
  }
}
