@media screen and (max-width: 991px) {
  .navbar-collapse {
    height: 100% !important;
  }
}
@media (max-width: 767px){
  .navbar .navbar-nav .dropdown-menu {
      max-height: 300px;
      overflow-y: scroll;
  }
}