.modalOverlay {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 1120;
  
}
.modal {
    
  
    
    justify-content: center;
    align-items: center;
   vertical-align: middle;
    width: 80%;
    height: 100%;
 
    
    
    
}
.disconnectButton  {
 align-self: center;
  appearance: none;
  -webkit-appearance: none;
  font-family: sans-serif;
  cursor: pointer;
  
  padding: 10px;
  min-width: 100px;
  border: 0px;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
   transition: background-color 100ms linear;
  background: #e74c3c;
  color: #ffffff;
}
.modal__content {
    position: relative;
    background-color: rgb(37, 36, 36);
    padding: 0.8em;
    border-radius: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 3px solid white;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
}
.close {
    
    color: red;
    font-size: 100px;
    
    
}
.bord {
    width: 80%;
    background-color: white;
    border-radius: 15px;
    height : 5px;
    
  }
.close:hover,
.close:focus {
  color: rgb(255, 0, 0);
  text-decoration: none;
  
}

.daddy {
    align-items: center;
    font-size: 21px;
    display: block;
    font-weight: bold;
    color: white;
    justify-content: center;
   
}

.ldsdualring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .ldsdualring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ldsdualring 1.2s linear infinite;
  }
  @keyframes ldsdualring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }