// Collapsed Sidebar

.app-sidebar-collapsed {
  .app-sidebar {

    .app-sidebar--widget {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }

    &:not(.app-sidebar--mini):hover {

      .app-sidebar--widget {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
